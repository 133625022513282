<template>
  <span class="s-lsm-label" :class="{'s-lsm-label--show': isOpen}">
    {{ currentLabel }}
    <span class="dropdown-chevron"></span>
  </span>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ALanguageSwitcher',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    currentLabel: {
      type: String,
      default: () => ''
    }
  }
});
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.s-lsm-label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: var(--dark-gray);
  z-index: 1;

  &--show {
    color: var(--orange);

    .dropdown-chevron:after {
      transform: rotate(225deg);
      top: 2px;
      border-color: var(--orange);
    }
  }
}
</style>
