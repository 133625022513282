<template>
  <div
    id="app"
    class="app"
    :class="routeBasedClass"
  >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import get from 'lodash/get'
import DefaultLayout from './layouts/Default'
import ErrorLayout from './layouts/Error'
import EmptyLayout from './layouts/Empty'
import CheckoutLayout from './layouts/Checkout'
import { processRedirect } from 'theme/helpers/redirect';
import { isServer } from '@vue-storefront/core/helpers';
import debounce from 'lodash/debounce';
import SmsTimer from 'theme/mixins/SmsTimer';

export default {
  components: {
    DefaultLayout,
    ErrorLayout,
    EmptyLayout,
    CheckoutLayout
  },
  mixins: [SmsTimer],
  data () {
    return {
      browserHeight: 0,
      browserWidth: 0
    };
  },
  provide () {
    const appProvided = {};
    Object.defineProperties(appProvided, {
      browserWidth: {
        get: () => this.browserWidth || 0
      },
      browserHeight: {
        get: () => this.browserHeight || 0
      }
    })
    return { appProvided };
  },
  computed: {
    layout () {
      return `${get(this.$route, 'meta.layout', 'default')}-layout`
    },
    routeBasedClass () {
      const currentPageComponent = this.$route.matched[0].components.default.name
      return currentPageComponent ? `${currentPageComponent.toLowerCase()}-page` : ''
    }
  },
  methods: {
    getBrowserSize: debounce(function () {
      if (isServer) return

      this.browserWidth = window?.innerWidth
      this.browserHeight = window?.innerHeight
    }, 300)
  },
  mounted: function () {
    // Prevent zooming on iOS devices
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.document.addEventListener('touchmove', e => {
        if (e.scale !== 1) {
          e.preventDefault();
        }
      }, { passive: false });
    }

    if (!isServer) {
      window.addEventListener('resize', this.getBrowserSize);

      const originalDescriptor = Object.getOwnPropertyDescriptor(Document.prototype, 'title')

      Object.defineProperty(document, 'title', {
        get: function () {
          return originalDescriptor.get.call(this)
        },
        set: function (value) {
          const stack = new Error().stack

          // Блокуємо зміну title від kwizbot
          if (stack.includes('widget-kwizbot.varus.ua')) {
            // console.log('Blocked title change from kwizbot')
            return
          }

          return originalDescriptor.set.call(this, value)
        }
      })
    }

    document.addEventListener('dblclick', (event) => {
      event.preventDefault();
    }, { passive: false });
  },
  beforeCreate () {
    processRedirect();
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getBrowserSize);
  },
  metaInfo () {
    const currentUrl = this.$router.resolve({
      path: this.$route.path
    });

    const logoUrl = this.$router.resolve({
      path: '/assets/logo.png'
    });

    return {
      meta: [
        { charset: 'utf-8' },
        { vmid: 'og:url', name: 'og:url', content: currentUrl.href },
        { vmid: 'og:image', name: 'og:image', content: logoUrl.href }
      ]
    }
  }
};
</script>

<style lang="scss" src="theme/css/main.scss"></style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

body {
  --overlay-z-index: var(--modal-mobile-overlay-index);
  --sidebar-aside-z-index: 2;
  --sidebar-z-index: 2;
  --bar-height: 3.125rem;
  --notification-font-size: var(--font-sm);
  font-family: var(--font-family-secondary);
  margin: 0;
  padding: 0;
  &.open-fullscreen-gallery {
    &:after {
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(51, 51, 51, 0.7);
      width: 100%;
      height: 100%;
      z-index: 11;
    }
  }

  @supports (-webkit-touch-callout: none) {
    --bottom-navigation-height: 4.5rem;
  }

  @include for-desktop {
    --overlay-z-index: var(--modal-overlay-index);
  }
  a {
    text-decoration: none;
    color: var(--c-link);
    cursor: pointer;
    &:hover {
      color: var(--c-link-hover);
    }
  }
}

#viewport {
  position: relative;
}

@include for-desktop {
  .sidebar {
    &__microcart {
      --sidebar-aside-width: 700px;
    }
  }
}

.grecaptcha-badge {
  z-index: 200;
  bottom: 70px!important;
}
</style>
